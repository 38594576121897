.main {
  overflow: auto;
}

.row {
  display: flex;
  align-items: center;
  margin: 0 5px 10px;
  height: 30px;
}

.stopSelectWrapper {
  display: inline-block;
  margin: 0 10px;
  flex: 1 1 1px;

}
