.main {
  border-radius: 2px;
  box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.12), inset 0 1px 3px 0 rgba(0, 0, 0, 0.31);
  border: solid 1px rgba(151, 151, 151, 0.32);
  padding: 5px;
  resize: none;
  flex: 1 1 auto;
  margin: 5px;

  &:disabled {
    background: transparent;
    border: 1px solid transparent;
    box-shadow: none;
    color: black
  }
}