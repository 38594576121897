.fullPage {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset: 0;
}

.icon {
  height: 40px
}
