.option {
  padding: 5px;
  display: block;
  width: 100%;
  text-align: left;

  &:hover {
    background-color: #d8d8d8;
  }
}

.name {
  font-size: 12px;
  white-space: nowrap;
  margin-bottom: 2px;
}

.address {
  font-size: 10px;
  white-space: nowrap;
}
