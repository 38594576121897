.iconBar {
  position: absolute;
  top: 15px;
  right: 5px;
  display: flex;

}

.iconButton {
  height: 15px;
  margin-left: 15px;
}

.icon {
  height: 100%;
}
