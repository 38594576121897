@import '../../constants';

.modal {
  width: 420px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.26);
}

.modalHeader {
  display: flex;
  margin: 15px 15px 0;
  color: #7a7a7a;
  font-size: 16px;
  justify-content: space-between;
  align-items: center;
}

.modalBody {
  min-height: 270px;
  padding: 45px;
  display: flex;
  flex-direction: column;
}

.modalFooter {
  display: flex;
  height: 60px;
}


.modalClose {
  padding: 10px;
  margin: -10px;
}

.modalCloseIcon {
  height: 12px;
}

.bodyFillerBefore {
  flex: 2 2 1px;
}
.bodyFillerAfter {
  flex: 5 5 1px;
}
.title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 24px;
}

.content {
  font-size: 16px;
}

$btn-border: solid 1px #e5e5e5;

.abort, .confirm {
  border-top: $btn-border;
  font-size: 14px;
  flex: 0 0 50%;
}
.abort {
  border-right: $btn-border;
  color: #757575;
}

.confirm {
  color: $cancel-color;
  font-weight: bold;
}

