@import '../../constants';

.wrapper {
  height: 21px;
  padding: 0 5px 0 7px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;

  &.onlyIcon {
    padding: 0;
  }
}

.driver {
  background: $user-type-badge-driver;
  .icon {
    height: 12px;
  }
}
.packer {
  background: $user-type-badge-packer;
  color: white;
  .icon {
    height: 12px;
  }
}
.manager {
  background: $user-type-badge-manager;
  .icon {
    height: 12px;
  }
}

.iconWrapper {
  width: 21px;
  height: 21px;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.text {
  margin-right: 5px;
}
