@import '../../constants';

.iconWrapper {
  display: inline-block;
  position: relative;
}

.icon {
  height: 12px;
}

.activeDot {
  height: 8px;
  width: 8px;
  background-color: $active;
  position: absolute;
  top: -4px;
  right: -4px;
  border-radius: 50%;
}
