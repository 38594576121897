@import '../../constants';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 0;
}

button.pageItem:hover {
  background-color: #ddd;
}

.pageItem {
  width: 40px;
  height: 40px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  &.selected {
    &, &:hover {
      background-color: $active;
    }
  }
}


.info {
  margin-right: 25px;
}
