@import '../../constants';


.mainCard,
.extraCard {
  display: flex;
}

.mainCard {
  position: relative;
  flex: 0 0 25%;
  padding: 10px;
  height: 220px;
}

.extraCard {
  margin: 25px 15px;
}

.circle {
  margin-left: auto;
  width: 25px;
  height: 25px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.29);
  background-color: #1a1a1a;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  height: 15px;
}

.menu {
  position: absolute;
  top: 10px;
  right: 40px;
  background: white;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.09);
}

.menuItem {
  padding: 10px 20px;
  color: black;
  font-size: 12px;
  font-weight: 700;
  width: 100%;
  text-align: left;

  &:not(:last-child) {
    border-bottom: 1px solid lightgray;
  }

  &:hover {
    background-color: $active;
  }
}