@import '../../constants';

.wrapper {
  position: absolute;
  padding: 5px;
  right: 0;
  bottom: 0;
  font-size: 12px;
  background-color: rgba(255, 255, 255, 0.8);
}

.upper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 5px;

}
.ex {
  margin-left: 5px;
}
.buttons {
  display: flex;
  justify-content: flex-end;
}

.icon {
  height: 15px;
}

.accept, .edit, .deny {
  text-decoration: underline;
  margin-left: 10px;
}

.accept {
color: $accept-color
}

.deny {
  color: $cancel-color
}

.loader {
  height: 15px;
}
