.icon {
  margin-left: 15px;
  line-height: 20px;

}
.wrapper {
  display: flex;
  align-items: center
}

