.confirmIcon {
  height: 15px;
  margin-right: 10px;
}
.confirmWrapper {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #d4d4d4;
  font-size: 12px;
}
