.option {
  display: flex;
  width: 100%;
  padding: 10px 5px;
  align-items: center;
  background: rgba(0, 0, 0, 0.18);
  &:hover { background: rgba(0,0,0,0.4); }
}

.badge {
  margin-right: 10px;
}
