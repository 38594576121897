$roboto-font-path: "./fonts/roboto";

@font-face {

  src: url("#{$roboto-font-path}/Roboto-Black.ttf") format("truetype");
  font-family: "Roboto";
  font-weight: 900;
}

@font-face {

  src: url("#{$roboto-font-path}/Roboto-BlackItalic.ttf") format("truetype");
  font-family: "Roboto";
  font-weight: 900;
  font-style: italic;
}

@font-face {

  src: url("#{$roboto-font-path}/Roboto-Bold.ttf") format("truetype");
  font-family: "Roboto";
  font-weight: 700;
}

@font-face {

  src: url("#{$roboto-font-path}/Roboto-BoldItalic.ttf") format("truetype");
  font-family: "Roboto";
  font-weight: 700;
  font-style: italic;
}

@font-face {

  src: url("#{$roboto-font-path}/Roboto-Light.ttf") format("truetype");
  font-family: "Roboto";
  font-weight: 300;
}

@font-face {

  src: url("#{$roboto-font-path}/Roboto-LightItalic.ttf") format("truetype");
  font-family: "Roboto";
  font-weight: 300;
  font-style: italic;
}


@font-face {

  src: url("#{$roboto-font-path}/Roboto-Medium.ttf") format("truetype");
  font-family: "Roboto";
  font-weight: 500;
}

@font-face {

  src: url("#{$roboto-font-path}/Roboto-MediumItalic.ttf") format("truetype");
  font-family: "Roboto";
  font-weight: 500;
  font-style: italic;
}

@font-face {

  src: url("#{$roboto-font-path}/Roboto-Thin.ttf") format("truetype");
  font-family: "Roboto";
  font-weight: 100;
}

@font-face {

  src: url("#{$roboto-font-path}/Roboto-ThinItalic.ttf") format("truetype");
  font-family: "Roboto";
  font-weight: 100;
  font-style: italic;
}

@font-face {

  src: url("#{$roboto-font-path}/Roboto-Regular.ttf") format("truetype");
  font-family: "Roboto";
  font-weight: 400;
}

@font-face {

  src: url("#{$roboto-font-path}/Roboto-Italic.ttf") format("truetype");
  font-family: "Roboto";
  font-weight: 400;
  font-style: italic;
}




