// Colors
$label-colour: #bbb;
$disabled-colour: #ddd;
$toggle-colour: #008a45;
$white: #fff;
$focus-color: #ff0;

.toggleSwitch {
  position: relative;
  margin-right: 10px;
  width: 75px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;

  &Checkbox {
    display: none;
  }

  &Label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid $label-colour;
    border-radius: 5px;
    margin: 0;

    &:focus {
      outline: none;

      >span {
        box-shadow: 0 0 2px 5px red;
      }
    }

    >span:focus {
      outline: none;
    }
  }

  &Inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;

    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 34px;
      padding: 0;
      line-height: 34px;
      font-size: 10px;
      color: white;
      font-weight: bold;
      box-sizing: border-box;
    }

    &:before {
      content: attr(data-yes);
      text-transform: uppercase;
      padding-left: 10px;
      background-color: $toggle-colour;
      color: $white;
    }
  }

  &Disabled {
    opacity: 0.75;
    cursor: not-allowed;

    &:before {
      opacity: 0.75;
      cursor: not-allowed;
    }
  }

  &Inner:after {
    content: attr(data-no);
    text-transform: uppercase;
    padding-right: 10px;
    background-color: $label-colour;
    color: $white;
    text-align: right;
  }

  &Switch {
    display: block;
    width: 24px;
    margin: 5px;
    background: $white;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 40px;
    border: 0 solid $label-colour;
    border-radius: 5px;
    transition: all 0.3s ease-in 0s;
  }

  &Checkbox:checked+&Label {
    .toggleSwitchInner {
      margin-left: 0;
    }

    .toggleSwitchSwitch {
      right: 0px;
    }
  }

  &.smallSwitch {
    width: 60px;

    .toggleSwitchInner {

      &:after,
      &:before {
        height: 20px;
        line-height: 20px;
      }
    }

    .toggleSwitchSwitch {
      width: 16px;
      right: 37px;
      margin: 4px;
    }
  }

  @media screen and (max-width: 991px) {
    transform: scale(0.9);
  }

  @media screen and (max-width: 767px) {
    transform: scale(0.825);
  }

  @media screen and (max-width: 575px) {
    transform: scale(0.75);
  }
}

.flexContainer {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  max-width: 300px;

}
