.datePickerContainer {
    display:flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 0px;
    font-size: 12px;
    font-weight: 500;
}

.marginLeft {
    margin-left:10px;
}

.optionButtons {
    text-decoration: underline;
    margin: 0px 10px;
    font-weight: normal;
}
