.wrapper {
  height: 16px;
  min-width: 40px;
  font-size: 12px;
  border: 1px solid black;
  border-radius: 1px;
  text-align: center;
  display: inline-block;
  padding: 0 5px;
  &.dark {
    background-color: black;
    color: white;
  }
}
