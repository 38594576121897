@import '../../constants';

.main {
  flex: 1 1 1px;
  display: flex;
  flex-direction: column;
}

.header {
  height: $header-height;
  background-image: $header-background-image;
  box-shadow: $header-box-shadow;
  display: flex;
  justify-content: flex-end;
}

.contentScrollContainer {
  overflow: auto;
  flex: 1 1 1px;
}

.content {
  margin-left: 10px;
  margin-right: 10px;
  height: 100%;
}

.flexGrowContainer {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
}
