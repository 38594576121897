@import '../../../constants';

.wrapper {
  display: flex;
  padding: 10px;
  flex-direction: column;
}

.keyProperty {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  height: 27px;
  &:last-child {
    margin-bottom: 0;
  }
}

.keyValue {
  margin-left: 10px;
  font-size: 12px;
  flex: 1 1 1px;
}

.textInput {
  width: 100%;
}

.nameWrapper {
  position: relative;
  margin-right: 60px;
}
.errorInput {
  border: 1px solid $error-color;
}

.errorMessage {
  font-size: 10px;
  color: $error-color;
  position: absolute;
  background: white;
  z-index: 5;
  box-shadow: 0 0 5px 10px white;

}
