.card {
  display: flex;
  padding: 10px;
}

.keyProperty {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }
}

.keyValue {
  margin-left: 10px;
  font-size: 12px;
}

.iconBar {
  position: absolute;
  top: 10px;
  right: 10px;
}

.linkIcon {
  height: 12px;
}


