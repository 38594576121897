@import '../../constants';

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 5px;
}

.headerRow {
  height: 40px;
}

.headerCell {
  text-align: left;
  font-size: 12px;
  border-bottom: 1px solid black;
  position: sticky;
  top: 0;
  background-color: $main-bg-color;
  padding-right: 5px;
  z-index: 10;


  &:first-child {
    padding-left: 5px;
  }

  &.right {
    text-align: right;
  }
}


.row {
  background: $records-list-row-dark;

  &:nth-child(odd) {
    background: $records-list-row-light;
  }

  height: 40px;
}

.cell {
  padding-right: 5px;
  font-size: 14px;

  &:first-child {
    padding-left: 13px;
  }

  &.right {
    text-align: right;
  }

  &.actionCell {
    text-align: center;
    background-color: inherit;
    width: 50px;

    &:hover {
      filter: brightness(90%);
    }
  }
}




.link {
  font-size: 11px;
  text-decoration: underline;
  margin: -10px 0;
  padding: 10px 0;
}