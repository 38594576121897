.imgWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.img {
  max-width: 100%;
  max-height: 100%;
}