@import '../../constants';

.wrapper {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: $icon-badge-bg;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}


