@import "../../_constants.scss";

.header {
  height: $header-height;
  background-image: $header-background-image;
  box-shadow: $header-box-shadow;
  font-size: 27px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 10px 18px;
  color: white;
}

.main {
  flex: 0 0 $side-menu-width;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.53);
}

.logoutButton {
  font-size: 16px;
  color: white;
  background: $logout;
  width: 100%;
  height: 60px;
  position: relative;
  flex: 0 0 auto;

}

.logoutIcon {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.menu {
  padding-top: 70px;
  overflow: auto;
}

.menuItem {

  display: flex;
  height: 50px;
  align-items: center;

  .menuIcon.onActive {
    display: none;
  }

  &.active {
    background: $active;
    .menuLabel {
      color: white;
    }
    .menuIcon {
      display: none;
      &.onActive {
        display: initial;
      }
    }

  }

}

.menuLabel {
  margin-left: 15px;
}

.menuIcon {
  margin-left: auto;
  margin-right: 22px;
  height: 24px;
}

.profile {
  margin-top: auto;
  height: 60px;
  flex-direction: column;
  justify-content: center;
  padding-left: 15px;
  display: flex;
  flex: 0 0 auto;
}

.name {
  font-size: 14px;
  margin-bottom: 2px;
}

.role {
  font-size: 9px;
}
