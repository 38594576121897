.wrapper {
    margin-top: 20px;
}

.text {
    text-align: center;
    margin: 50px;
}

.dateContainer {
    display: flex;
    justify-content: flex-end;
}
