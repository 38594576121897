.loaderWrapper {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  flex-direction: column;
}

.loader {
  height: 40px;
}

.text {
  text-align: center;
  margin-top: 20px;
}
