@import '../../constants';

.main {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  display: flex;
  background: $main-bg-color;
}
