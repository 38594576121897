.chartWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5rem;
    padding: 20px;
}

@media only screen and (min-width: 1500px) {
  .threeColumns {
      grid-template-columns: 1fr 1fr 1fr;
  }
}

.chartCentered {
    display: flex;
    justify-content: center;
    padding: 20px;
}

.header {
    font-size: 15px;
    font-weight: 500;
    padding-bottom: 10px;
    color: black;
    margin-top: 50px;
    margin-bottom: 0px;
}

.text {
    text-align: center;
}

.tabSelected {
    background-color: transparent;
    color: #CA0036;
}

.tabPadding {
    padding-top: 5px;
}

.paddingBottom {
    padding-bottom: 100px;
}

.pieChart {
    max-width: 300px;
    max-height: 300px;
}

.pieWrapper {
    display: flex;
    justify-content: center;
}

.tableWidth {
    width: 100%;
}

.chartWidth {
    width: 100%;
    max-width: 800px;
}