@import '../../constants';

.unconfirmedCount {
  margin-left: 30px;
  background-color: $unconfirmed-color;
  color: white;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
