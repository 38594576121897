.root {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  background-image: url("../../img/rb_bg.png");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  width: 407px;
  height: 248px;
  border-radius: 5px;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginPin {
  margin-top: -24px
}

.welcome {
  margin-top: 29px;
  font-size: 18px;
  text-align: center;
  font-weight: 500;
}

.loginButton {
  margin-top: auto;
  margin-bottom: 24px;
  color: white;
  padding: 10px 65px;
  border-radius: 20px;
  background-color: black;
  font-size: 14px;
  font-weight: 500;
}


