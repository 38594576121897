.wrapper {
  display: flex;
  align-items: center;
  padding: 0 5px;
}

.number {
  margin-left: 10px;
}

.keyWrapper {
  margin: 25px 15px;
}

.circle {
  margin-left: auto;
  width: 25px;
  height: 25px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.29);
  background-color: #1a1a1a;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  height: 15px;
}
