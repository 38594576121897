@import './reset';
@import './roboto_font';

* {
  box-sizing: border-box;
}

[role="button"] {
  cursor: pointer;
}

body {
  font-family: Roboto, serif;
}

button {
  cursor: pointer;
  display: block;
}

a {
  cursor: pointer;
}

body {
  // necessary for react-tiny-popover
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

input[type='number']::-webkit-inner-spin-button {
  display: none;
}
