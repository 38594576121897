.container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;

  &>.cardWrapper,
  &>.container {
    flex: 0 0 25%;
  }



  .container {
    margin-left: 0;
    margin-right: 0;
  }

  &.vertical {
    flex-direction: column;

    &>.cardWrapper,
    &>.container {
      flex: initial;
    }
  }

}



.card {
  margin: 10px;
  border-radius: 2px;
  overflow: visible;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.09);
  background-image: linear-gradient(172deg, white 12%, #f9f9f9 87%);

  height: 90px;
  position: relative;

  display: flex;
  flex-direction: column;

  &.large {
    height: 200px;
  }

  &.autoHeight {
    height: auto;
  }
}

.title {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
  padding: 15px 5px 0 5px;

}

.contentContainer {
  padding: 5px;
}