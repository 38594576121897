.fullPage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60%;
}

.icon {
  height: 40px
}
