.addMoreKeys {
  color: #8c8c8c;
  font-style: italic;
  font-size: 12px;
  padding: 10px;
}

.hidden {
  visibility: hidden;
}

.header {
    font-size: 15px;
    font-weight: 500;
    padding-bottom: 10px;
    color: black;
    margin-top: 50px;
    margin-bottom: 0px;
}


.text {
    text-align: center;
    margin: 50px;
}