.addIcon {
  margin-right: 10px;
}


.link {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  margin-left: auto;
  color: white;
  text-decoration: underline;
  font-size: 12px;
}
