
.wrapper {
  &:nth-child(3n+1) {
    background-color: #8e2e0b;
  }
  &:nth-child(3n+2) {
    background-color: #fdc431;
  }
  &:nth-child(3n) {
    background-color: #ca0037;
  }

  color: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 12px;
  display: inline-grid;
  place-items: center;
}
