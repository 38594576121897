.searchContainer {
    display: flex;
    border: 1px solid grey;
    border-radius: 4px;
    overflow: hidden;
    margin: 8px;
    background-color: transparent;
    width: 500px;
}

.searchInput {
    font-size: 16px;
    color: white;
    padding: 10px;
    outline: none;
    width: 100%;
    background-color: transparent;
    border: none;
}

.clearButton {
    display: flex;
    align-items: center;
    padding: 0 10px;

    svg {
        fill: white;
        height: 16px;
        width: 16px;
    }
}

.searchButton {
    padding: 0 15px;
    color: black;
    background-color: white;
}