@import '../../constants';

.main {
  background-color: $filterselect-bg;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.option {
  color: white;
  height: 32px;
  display: flex;
  font-size: 10px;
  width: 100%;
  align-items: center;
  padding: 0 10px;
  text-align: left;

  &:hover {
    background-color: $filterselect-hover;
  }
}

.checkboxWrapper {
  margin-left: auto;
}

.inputWrapper {
  flex: 0 0 auto;
  padding: 10px 5px;
}

.input {
  width: 100%;
  height: 20px;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.39);
  border: solid 0.5px #979797;
  font-size: 8px;
}

.optionsList {
  flex: 1 1 1px;
  min-height: 0;
  overflow: auto;
}

.loadingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loadingIcon {
  height: 30px;
}
