@import '../../constants';

.titleBar {
  padding: 10px 0;
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.buttonBar {
  display: flex;
  margin: 0 10px 0 auto;
}

.title {
  display: flex;
  font-size: 16px;
}

.cancelButton, .acceptButton {
  text-decoration: underline;
  font-size: 12px;
  padding: 0 5px;
}

.cancelButton {
  color: $cancel-color;
}

.loading {
  height: 20px;
  margin-right: 10px;
}
