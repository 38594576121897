$side-menu-width: 300px;
$header-height: 50px;
$header-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.22);
$header-background-image: linear-gradient(to bottom, #5b5a5a, #2b2b2b 2%, #000);

// colors
$logout: #ca0037;
$active: #fdc431;

$records-list-row-dark: #d8d8d8;
$records-list-row-light: #f5f5f5;

$filterselect-bg: #4a4a4a;
$filterselect-hover: #fdc431;

$user-type-badge-driver: linear-gradient(to bottom, #f6b02b, #cb8f41);
$user-type-badge-packer: linear-gradient(to bottom, #262626, #1c1c1c 27%, #000);
$user-type-badge-manager: linear-gradient(to bottom, #d75b0f, #d05a1d 42%, #c65831);

$icon-badge-bg: #ffd464;

$loader-color: #ca0037;

$cancel-color: #ca0037;

$unconfirmed-color: #ca0037;

$error-color: #ca0037;

$main-bg-color: #fafafa;

$accept-color: #42bd41;